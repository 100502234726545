import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto winpe extract`}</strong>{` -- extract certificates from Windows Portable Executable files`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto winpe extract <file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto winpe extract`}</strong>{` extract certificate from a Windows Portable Executable file in PEM format.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto winpe`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`file`}</inlineCode>{`
The path to a Windows Portable Executable file`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      